"use strict";

// $(function(){
// 	var wrapper = '.js-campaignSwiper__box';
// 	var swiper = new Swiper(wrapper +' .js-campaignSwiper', {
// 		slidesPerView:3,
// 		loop: true,
// 		slidesPerGroup: 1,
// 		//updateOnImagesReady: true,
// 		//preloadImages: true,
// 		loopAdditionalSlides: 1,
// 		spaceBetween: 20,
// 		speed:800,
// 		autoplay: {
// 			delay:3000,
// 		},
// 		navigation: {
// 			nextEl: wrapper +' .swiper-button-next',
// 			prevEl: wrapper +' .swiper-button-prev',
// 		},
// 		pagination: {
// 			el: wrapper +" .swiper-pagination",
// 			type: "bullets",
// 			//type: "fraction",
// 			clickable: true,
// 		},
// 		breakpoints: {
// 			500: {
// 			  slidesPerView: 1,
// 			  spaceBetween: 10
// 			},
// 		}
// 	});
// });
// 1vh辺りのpixel数を取得
var vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', "".concat(vh, "px")); //CSS変数「--vh」に高さを格納

var fixFooterBottom = document.getElementsByClassName('l-fixFooter__bottom'); //console.log(fixFooterBottom[0]);

var fixFooterBottomHeight = fixFooterBottom[0] ? fixFooterBottom[0].clientHeight : 0;
document.documentElement.style.setProperty('--fixFooterBottomHeight', "".concat(fixFooterBottomHeight, "px")); //CSS変数「--fixFooterBottomHeight」に高さを格納

var header = document.getElementsByClassName('l-header'); //console.log(header[0]);

var headerHeight = header[0] ? header[0].clientHeight : 0;
document.documentElement.style.setProperty('--headerHeight', "".concat(headerHeight, "px")); //CSS変数「--headerHeight」に高さを格納

var headerBar = document.getElementsByClassName('l-header__bar'); //console.log(header[0]);

var headerBarHeight = headerBar[0] ? headerBar[0].clientHeight : 0;
document.documentElement.style.setProperty('--headerBarHeight', "".concat(headerBarHeight, "px")); //CSS変数「--headerBarHeight」に高さを格納

$(function () {
  $.ajax({
    type: 'GET',
    url: 'https://klonklonklon.com/apps/note/wp-json/wp/custom/common',
    dataType: 'json'
  }).done(function (json) {
    //console.dir(json);
    // var news = json.news;//お知らせ一覧を取得
    // var columns = json.columns;//コラム一覧を取得
    // var blogs = json.blogs;//ブログ一覧を取得
    // var topics = json.topics;//特集バナーを取得
    // var snaps = json.snaps;//関連スナップを取得
    // var shops = json.shops;//ショップ一覧
    new Promise(function (resolve, reject) {
      setNews(json.news, '.js-ajax__wp-posts');
      setColumns(json.columns, '.js-ajax__wp-column');
      setBlogs(json.blogs, '.js-ajax__wp-blog');
      setShops(json.shops, '.l-search__info__cont__list03'); // console.log(json.topics);

      setTopics(json.topics, '.js-ajax__wp-pickup');
      resolve();
    }).then(function () {
      console.log('JSON処理完了[COMMON]');
    }).catch(function () {
      console.error();
    });
  }).fail(function (json) {
    console.error('WordPressのブログ記事取得に失敗しました。[COLUMN]'); // $('.l-column').remove();
  }); //お知らせの一覧用DOMを生成して埋め込む

  function setNews(array, targetEle, removeEle) {
    if (array.length > 0) {
      console.log('"NEWS" data is existing');
      var html = '';
      $.each(array, function (i, row) {
        // console.log(row);
        var title = row.title; //タイトル

        var url = row.url; //URL

        var date = row.date; //投稿日

        var cat_name = row.category[0].cat_name;
        var cat_url = row.category[0].cat_url;
        var postID = row.ID; //投稿ID

        html += '<li>';
        html += '<dl class="l-search__info__cont__list01__layout">';
        html += '<dt class="l-search__info__cont__list01__cap"><a class="l-search__info__cont__list01__cat" href="' + cat_url + '">' + cat_name + '</a><time datetime="' + date + '">' + date + '</time></dt>';
        html += '<dd class="l-search__info__cont__list01__txt"><a href="https://klonklonklon.com/apps/note/news/#news' + postID + '">' + title + '</a></dd>';
        html += '</dl>';
        html += '</li>\n';
      }); // console.log(html);

      if (targetEle != null) {
        $(targetEle).append(html);
      }

      ;
    } else {
      console.log('"NEWS" data is nothing');

      if (removeEle != null) {
        $(removeEle).remove();
      } else {
        $(targetEle).apeend('<li>該当する記事がありません。</li>');
      }

      ;
    }
  } //コラムの一覧用DOMを生成して埋め込む


  function setColumns(array, targetEle, removeEle) {
    if (array.length > 0) {
      console.log('"COLUMN" data is existing');
      var html = '';
      $.each(array, function (i, row) {
        // console.log(row);
        var title = row.title; //タイトル

        var link = row.url; //URL

        var date = row.date; //投稿日

        var thumbnail = row.image; //画像

        html += '<li>';
        html += '<figure class="l-search__info__cont__list02__img"><a href="' + link + '"><img src="' + thumbnail + '" alt="' + title + '"></a></figure>';
        html += '<dl class="l-search__info__cont__list02__layout">';
        html += '<dt class="l-search__info__cont__list02__cap"><time datetime="' + date + '">' + date + '</time></dt>';
        html += '<dd class="l-search__info__cont__list02__txt"><a href="' + link + '">' + title + '</a></dd>';
        html += '</dl>';
        html += '</li>\n';
      }); // console.log(html);

      if (targetEle != null) {
        $(targetEle).append(html);
      }

      ;
    } else {
      console.log('"COLUMN" data is nothing'); // if(removeEle != null){ $(removeEle).remove() }else{$(targetEle).apeend('<li>該当する記事がありません。</li>')};
    }
  } //ブログの一覧用DOMを生成して埋め込む


  function setBlogs(array, targetEle, removeEle) {
    if (array.length > 0) {
      console.log('"BLOG" data is existing');
      var html = '';
      $.each(array, function (i, row) {
        // console.log(row);
        var title = row.title; //タイトル

        var link = row.url; //URL

        var date = row.date; //投稿日

        var thumbnail = row.image; //画像

        html += '<li>';
        html += '<figure class="l-search__info__cont__list02__img"><a href="' + link + '"><img src="' + thumbnail + '" alt="' + title + '"></a></figure>';
        html += '<dl class="l-search__info__cont__list02__layout">';
        html += '<dt class="l-search__info__cont__list02__cap"><time datetime="' + date + '">' + date + '</time></dt>';
        html += '<dd class="l-search__info__cont__list02__txt"><a href="' + link + '">' + title + '</a></dd>';
        html += '</dl>';
        html += '</li>\n';
      }); // console.log(html);

      if (targetEle != null) {
        $(targetEle).append(html);
      }

      ;
    } else {
      console.log('"BLOG" data is nothing'); // if(removeEle != null){ $(removeEle).remove() }else{$(targetEle).apeend('<li>該当する記事がありません。</li>')};
    }
  } //店舗一覧用DOMを生成して埋め込む


  function setShops(array, targetEle, removeEle) {
    if (array.length > 0) {
      console.log('"SHOP" data is existing');
      var html = '';
      $.each(array, function (i, row) {
        // console.log(row);
        var title = row.title; //タイトル

        var url = row.url; //URL

        var thumbnail = row.image; //画像

        var address = row.address; //住所

        var tel = row.tel; //電話番号

        var business_hours = row.business_hours; //営業時間

        var temporary_business_hours = row.temporary_business_hours; //臨時営業時間

        var close_day = row.close_day; //休日

        html += '<li>';
        html += '<figure class="l-search__info__cont__list03__img"><a href="' + url + '"><img src="' + thumbnail + '" alt="' + title + '"></a></figure>';
        html += '<dl class="l-search__info__cont__list03__layout">';
        html += '<dt class="l-search__info__cont__list03__name">' + title + '</dt>';
        html += '<dd class="l-search__info__cont__list03__txt">';
        html += '<div class="l-search__info__cont__list03__block">';
        html += address ? '<dl><dt>住所：</dt><dd>' + address + '</dd></dl>\n' : '';
        html += tel ? '<dl><dt>TEL：</dt><dd>' + tel + '</dd></dl>\n' : '';
        html += business_hours ? '<dl><dt>営業時間：</dt><dd>' + business_hours + '</dd></dl>\n' : '';
        html += temporary_business_hours ? '<dl><dt>臨時営業時間：</dt><dd>' + temporary_business_hours + '</dd></dl>\n' : '';
        html += close_day ? '<dl><dt>休日：</dt><dd>' + close_day + '</dd></dl>\n' : '';
        html += '<dl>';
        html += '</div>';
        html += '<div class="c-btn03"><a href="' + url + '">詳細はこちら</a></div>';
        html += '</dd>';
        html += '</dl>';
        html += '</li>\n';
      }); // console.log(html);

      if (targetEle != null) {
        $(targetEle).append(html);
      }

      ;
    } else {
      console.log('"SHOP" data is nothing'); // if(removeEle != null){ $(removeEle).remove() };
    }
  } //特集一覧用DOMを生成して埋め込む


  function setTopics(array, targetEle, removeEle) {
    new Promise(function (resolve, reject) {
      if (array.length > 0) {
        console.log('"TOPIC" data is existing');
        var html = '';
        var html_menu = '';
        var html_footer = '';
        var html_header_id = 'header-menu'; //「ヘッダーメニュー」のタームIDを設定

        var html_footer_id = 'footer-cp'; //「フッターキャンペーン」のタームIDを設定

        var html_menu_id = 'humberger'; //「ハンバーガーメニュー」のタームIDを設定

        $.each(array, function (i, row) {
          // console.log(row);
          var title = row.title; //タイトル

          var link = row.url; //URL

          var date = row.date; //日付

          var img_normal = row.img_normal; //1000px×750px

          var img_pc = row.img_pc; //1920px×1080px

          var img_sp = row.img_sp; //1080px×1920px

          var img_square = row.img_square; //1080px×1080px

          var img_rectangle = row.img_rectangle; //460px×160px

          var desc = row.description;
          var display = row.display.length > 0 ? new RegExp(String(row.display.join('|'))) : null; // console.log(display);

          if (display != null) {
            if (display.test(html_header_id)) {
              html += '<li><a href="' + link + '"><img src="' + img_normal + '" alt="' + title + '"><span class="txt">' + title + '</span></a></li>\n';
            }

            if (display.test(html_footer_id)) {
              html_footer += '<li class="swiper-slide"><time datetime="' + date + '"><a href="' + link + '"><img src="' + img_rectangle + '" alt="' + title + '"></a></time></li>\n';
            }

            if (display.test(html_menu_id)) {
              html_menu += '<li><time datetime="' + date + '"><a href="' + link + '"><img src="' + img_rectangle + '" alt="' + title + '"><strong>' + desc + '</strong></a></time></li>\n';
            }
          }
        }); // console.log(html_footer);

        html != '' ? $('.js-ajax__wp-pickup').append(html) : $('.l-pickup').remove();
        html_footer != '' ? $('.js-ajax__wp-pickup__footer').append(html_footer) : $('.l-campaign').remove();
        html_menu != '' ? $('.js-ajax__wp-pickup__menu').append(html_menu) : $('.l-banner').remove();
      } else {
        console.log('"TOPIC" data is nothing'); // if(removeEle != null){ $(removeEle).remove() };
      }

      resolve();
    }).then(function () {
      console.log('JSON処理完了'); // フッターキャンペーンのswiper

      var wrapper = '.js-campaignSwiper__box';
      var swiper = new Swiper(wrapper + ' .js-campaignSwiper', {
        slidesPerView: 3,
        loop: true,
        slidesPerGroup: 1,
        //updateOnImagesReady: true,
        //preloadImages: true,
        loopAdditionalSlides: 1,
        spaceBetween: 20,
        speed: 800,
        autoplay: {
          delay: 3000
        },
        navigation: {
          nextEl: wrapper + ' .swiper-button-next',
          prevEl: wrapper + ' .swiper-button-prev'
        },
        pagination: {
          el: wrapper + " .swiper-pagination",
          type: "bullets",
          //type: "fraction",
          clickable: true
        },
        breakpoints: {
          500: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        }
      });
    }).catch(function () {
      console.error();
    });
  }
}); // $(document).ready(function(){
// 	/*	let url = location.href;
// 	console.log(url);
// 	if(url.indexOf('omotenashi2020') > -1){
// 		$('#bnrOmotenashi2020').css({'display':'block'});
// 		console.log('含まれてる');
// 	}else{
// 		$('#bnrOmotenashi2020').css({'display':'none'});
// 			console.log('含まれてない');
// 	}*/
// 	if(location.href.indexOf('pid') < 0) {
// 		let private_arr = ['159540248'];
// 		$('a').each(function(){
// 			let transition_destination = $(this).attr('href').split('=');
// 			if(private_arr.indexOf(transition_destination[1]) >= 0) {
// 				$(this).parent('li').remove();
// 				$(this).parent('div').parent('li').remove();
// 				$(this).remove();
// 			}
// 		});
// 	}
// });
//GAイベントクリックを包括的に制御（data-gtag属性で制御）

$(function () {
  $('*[data-gtag]').each(function () {
    var category = $(this).data('gtag');
    var target = $(this).find('a');
    target.on('click', function () {
      if ($(this).find('img').length) {
        var label = $(this).find('img').attr('alt');
        console.log(label);
      } else {
        var label = $(this).text();
      }

      gtag('event', 'click', {
        'event_category': category,
        'event_label': label
      });
    });
  });
}); //スムーススクロール

$(function () {
  $('a[href^="#"]').not('.overview').click(function () {
    var adjust = headerHeight;
    var speed = 500;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top - adjust;
    $("html, body").animate({
      scrollTop: position
    }, speed, "swing");
    return false;
  });
}); //jsによる時限表示機能

$(document).ready(function () {
  $(".js-view-timer").each(function (index, target) {
    var startDate = $(this).attr("data-start-date");
    var endDate = $(this).attr("data-end-date");
    var nowDate = new Date();

    if (startDate) {
      startDate = new Date(startDate);
    } else {
      startDate = nowDate;
    }

    if (endDate) {
      endDate = new Date(endDate);
    }

    if (startDate <= nowDate && (!endDate || nowDate <= endDate)) {
      $(this).show(); //$(this).wrap();
    } else {
      $(this).hide(); //$(this).wrap("<!-- -->");
    }
  });
}); //new ClipboardJS('.copy-value');

var clipboard = new ClipboardJS('.copy-value'); // Select all .copy-value items

var btns = document.querySelectorAll('.copy-value'); // Remove .tooptip class by mouseout

for (var i = 0; i < btns.length; i++) {
  btns[i].addEventListener('mouseleave', clearTooltip);
}

function clearTooltip(e) {
  e.currentTarget.setAttribute('class', 'copy-value');
} // Add .tooltip class when it's clicked


function showTooltip(elem) {
  elem.setAttribute('class', 'copy-value tooltip');
}

clipboard.on('success', function (e) {
  showTooltip(e.trigger);
});